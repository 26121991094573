import * as Sentry from '@sentry/nextjs';
import { clsx, type ClassValue } from 'clsx';
import { parseISO, format } from 'date-fns';
import { redirect } from 'next/navigation';
import { twMerge } from 'tailwind-merge';

import { UserData } from '@/lib/types';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function transformUserData(userData: unknown): UserData {
  if (typeof userData !== 'object' || userData === null) {
    Sentry.withScope((scope) => {
      scope.setLevel('error');
      scope.setExtra('userData', userData);
      scope.setTag('location', 'transformUserData');
      Sentry.captureException('Invalid userData');
    });
    redirect('/invalid');
  }

  const firstTenant =
    typeof userData === 'object' &&
    userData !== null &&
    'firstTenant' in userData &&
    typeof (userData as Record<string, unknown>).firstTenant === 'object'
      ? ((userData as Record<string, unknown>).firstTenant as Record<string, unknown>)
      : {};

  return {
    ...userData,
    firstTenant: {
      ...firstTenant,
      phoneNumber: firstTenant.phoneNumber
        ? (firstTenant.phoneNumber as string).trim().replace(/^0/, '+49')
        : '',
      dateOfBirth: firstTenant.dateOfBirth
        ? format(parseISO(firstTenant.dateOfBirth as string), 'dd.MM.yyyy')
        : '',
    },
  } as unknown as UserData;
}

export function mergeRefs<T>(...refs: React.Ref<T>[]): React.RefCallback<T> {
  return (node: T | null) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(node);
      } else if (ref != null) {
        (ref as React.MutableRefObject<T | null>).current = node;
      }
    });
  };
}
